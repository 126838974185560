<template>
<div class="tgju-widget-content full-padding">
    <div class="row tgju-widgets-row">
        <div class="tgju-widgets-block col-12 widget-border-fix">
            <Followers :username="$store.state.user.username"></Followers>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.widget-min-box-content {
    .l-title {
        display: flex;
    }

    .edit-note {
        font-size: 14px;
        cursor: pointer;
        margin-right: -3px;
    }
}
</style>

<script>
// این ویو برای صفحه فالوئر های کاربر میباشد
import Followers from '@/components/Profile/Followers.vue'

export default {
    name: 'ProfileFollowersView',
    props: [],
    components: {
        Followers
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {},
}
</script>
